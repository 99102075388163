import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("token");

  const auth = token == "accepted" ? true : false;

  return auth ? <Outlet /> : <Navigate to="/notfound" />;
};
