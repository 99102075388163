import axios from "axios";
import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/Stories.css";
import { getMedia } from "../utils";

export const Stories = () => {
  const id = localStorage.getItem("sowId");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [index, setIndex] = useState("");
  useEffect(() => {
    const api = `${getMedia}?sow_id=${id}`;
    console.log(api, "api");
    setLoading(true);
    axios
      .get(api)
      .then((res) => {
        console.log(res.data.media, "res");
        setData(res.data.media);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setIsError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <>
      <Carousel variant="dark">
        {data.map((item, index) => (
          <Carousel.Item key={item.media_id}>
            <img
              className="d-block w-100"
              src={item.media_image}
              alt="First slide"
            />
            <Carousel.Caption>
              {/* <h3>First slide label</h3> */}
              <p
                style={{
                  // color: "black",
                  fontSize: "13px",
                  fontFamily: "DM Sans, sans-serif",
                  fontWeight: "900",
                }}
              >
                {item.description}
              </p>
              <div>
                {Number(index) + 1}
                {"/"}
                {data.length}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};
