import { Avatar, Button, Divider, Paper } from "@mui/material";
import React from "react";
import SecurityIcon from "@mui/icons-material/Security";
import Chip from "@mui/material/Chip";
import TaskmoIcon from "../assets/taskmologo.svg";
import Registration from "../assets/registration_success.gif";
import ShieldIcon from "../assets/shield-icon.svg";
import "../css/Confirmation.css";
import { useNavigate } from "react-router-dom";
import Plus from "../assets/plus.svg";
import axios from "axios";

const ConfirmationPage = () => {
  const brandLogo = localStorage.getItem("brandlogo");
  const utmLink = localStorage.getItem("utmLink");
  const merNum = localStorage.getItem("mernumber");
  const [link1488, setLink1488] = React.useState("");

  const sowIDD = localStorage.getItem("sowId");
  console.log(brandLogo, "brandlogo");
  const navigate = useNavigate();

  const handleLink1488 = () => {
    let link = "https://app.backend.taskmo.co/sharemedia/getUTMLink";
    axios
      .get(link, { params: { sow_id: sowIDD, merchant_number: merNum } })
      .then((res) => {
        window.location.href = res.data.utm_link;
        setLink1488(res.data.utm_link);
      })
      .catch((err) => err);
  };
  return (
    <div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Avatar
          src={TaskmoIcon}
          sx={{ height: "70px", width: "70px" }}
          style={{ objectFit: "cover" }}
        />
      </div> */}
      <div className="abovegif">
        <img src={Registration} className="gifImagesuc" />
      </div>
      <div>
        <div
          className="belowgif"
          style={{ fontSize: "12px", fontWeight: "600" }}
        >
          Congratulations
        </div>
      </div>
      <div
        className="congmessg belowgif"
        style={{ fontSize: "11px", alignItems: "flex-end" }}
      >
        <div className="thanksregis">Thanks for registering on </div>

        <div style={{ marginTop: "10px" }}>
          <Avatar src={brandLogo} sx={{ height: "18px", width: "18px" }} />
        </div>
      </div>
      {/* <Divider
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "center",
          marginLeft: "5%",
        }}
      /> */}
      <div className="belowMainmatterdiv">
        <div className="maindownmatter" style={{ alignItems: "baseline" }}>
          <div className="steps">Step&nbsp;1&nbsp;&nbsp;:</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div style={{ fontFamily: "Nunito", paddingTop: "5px" }}>
              Click on the below link to start your registration process and
              follow your partner instructions.
            </div>
            <div>
              {sowIDD == "1488" ? (
                <Button
                  onClick={handleLink1488}
                  className="muibuttondown"
                  variant="outlined"
                  style={{
                    fontFamily: "Nunito",
                    borderColor: "#6759b1",
                    color: "#6c4282",
                    fontWeight: "600",
                  }}
                >
                  download
                </Button>
              ) : (
                <a href={utmLink} style={{ textDecoration: "none" }}>
                  <Button
                    className="muibuttondown"
                    variant="outlined"
                    style={{
                      fontFamily: "Nunito",
                      borderColor: "#6759b1",
                      color: "#6c4282",
                      fontWeight: "600",
                    }}
                  >
                    download
                  </Button>
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="maindownmatter" style={{ alignItems: "baseline" }}>
          <div className="steps">Step&nbsp;2&nbsp;&nbsp;:</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div style={{ fontFamily: "Nunito" }}>
              Use the below link to understand how to self register and complete
              the process without the help of your partner presence .
            </div>
            <div>
              <Button
                className="muibuttondown"
                variant="outlined"
                style={{
                  fontFamily: "Nunito",
                  borderColor: "#6759b1",
                  color: "#6c4282",
                  fontWeight: "600",
                }}
                onClick={() => navigate("/stories")}
              >
                Know more
              </Button>
            </div>
          </div>
        </div>

        {/* <div className="maindownmatter" style={{ alignItems: "baseline" }}>
          <div className="steps">Step&nbsp;3&nbsp;&nbsp;:</div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div style={{ fontFamily: "Nunito" }}>
              Please provide any documents requested from your partner to
              process the proposal further .
            </div>
            <div></div>
          </div>
        </div> */}

        {/* <div
          className="maindownmatter"
          style={{ alignItems: "baseline", justifyContent: "space-around" }}
        >
          <div>
            <Button
              variant="outlined"
              style={{ width: "100px", height: "50px" }}
            >
              <img src={Plus} />
            </Button>
          </div>
          <div>
            <Button
              variant="outlined"
              style={{ width: "100px", height: "50px" }}
            >
              <img src={Plus} />
            </Button>
          </div>
          <div>
            <Button
              variant="outlined"
              style={{ width: "100px", height: "50px" }}
            >
              <img src={Plus} />
            </Button>
          </div>
        </div> */}
      </div>

      <Paper
        elevation={0}
        style={{ border: "none" }}
        sx={{
          marginTop: "calc(10% + 60px)",
          position: "fixed",
          bottom: 0,
          width: "100%",
          border: "none",
        }}
        component="footer"
        // square
        // variant="outlined"
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Chip
            label="Your data is 100% safe with us."
            avatar={<img src={ShieldIcon} />}
            style={{
              padding: "10px",

              fontSize: "10px",
              borderRadius: "4px",
              color: "#001DF8",
              background: "#F5F5FF",
              fontFamily: "Nunito",
            }}
          />
        </div>
      </Paper>
    </div>
  );
};

export default ConfirmationPage;
