import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TimerIcon from "@mui/icons-material/Timer";

import { Grid, Typography } from "@mui/material";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
// import { verifyCode } from "../utils";
import Swal from "sweetalert2";

const OTPModal = ({
  fullWidth,
  maxWidth,
  open,
  handleClose,
  mbnumber,
  api2,
  sowIdcheck,
  lat,
  long,
  lang,
  langId,
}) => {
  console.log(lang, langId, "checking");
  const [code, setCode] = useState("");
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  const handleChangeInput = (code) => setCode(code);
  let api =
    sowIdcheck == "1341"
      ? "https://app.backend.taskmo.co/niyoglobal/verify_code"
      : api2;

  useEffect(() => {
    let id;
    if (timer > 0) {
      id = setTimeout(timeOutCallback, 1000);
    }
    return () => clearInterval(id);
  }, [timer, timeOutCallback]);
  const handleOtp = () => {
    const postData =
      sowIdcheck == "1341"
        ? {
            customer_number: mbnumber,
            verification_code: code,
            language: lang,
            language_id: langId,
            latlong: `${lat},${long}`,
          }
        : {
            merchant_number: mbnumber,
            verification_code: code,
            language: lang,
            language_id: langId,
            latlong: `${lat},${long}`,
          };

    axios
      .post(api, postData)
      .then((res) => {
        console.log(res, "faska");
        if (res.data.error) {
          Swal.fire(res.data.message);
        } else {
          localStorage.setItem("token", "accepted");
          if (sowIdcheck == "1341") {
            navigate(`/confirmationpage`);
          } else {
            navigate(`/confirmation`);
          }
        }
        //for now for testing
      })
      .catch((err) => {
        console.log(err, "err");
        Swal.fire("something is wrong");
      });

    handleClose();
  };

  return (
    <div>
      <Dialog
        style={{
          borderRadius: "200px",
          display: "flex",
          justifyContent: "center",
          fontFamily: "Nunito",
        }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <div
            style={{
              fontFamily: "Nunito",
              fontWeight: "600",
              marginTop: "10px",
            }}
          >
            OTP Verification
          </div>
          <Typography variant="caption" display="block" gutterBottom>
            <div style={{ fontFamily: "Nunito" }}>
              we have sent a verification code on your mobile number &nbsp;{" "}
              <span style={{ color: "#7840C3" }}>+91 {mbnumber}</span>
            </div>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            Container
            alignItems={"center"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Grid item style={{ marginLeft: "15px" }}>
              <OtpInput
                value={code}
                onChange={handleChangeInput}
                numInputs={4}
                separator={<span style={{ width: "20px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  //   border: "1px solid transparent",
                  border: "1px solid #CFD3DB",
                  borderRadius: "16px",
                  width: "44px",
                  height: "54px",
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            mt={1}
            alignItems={"center"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Grid item>
              <TimerIcon />
            </Grid>
            <Grid item>
              <Typography variant="caption" color="initial">
                00 : {timer < 10 ? 0 : null}
                {timer}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            mt={1}
            variant="caption"
            display="block"
            gutterBottom
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <div style={{ fontFamily: "Nunito" }}>
              Didn’t receive the code?&nbsp;{" "}
              <span style={{ color: "#BF615B" }}>Resend OTP</span>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{ height: 50, width: "100%" }}
            style={{
              background:
                code.length == 4
                  ? "linear-gradient(#8A7DD1, #643497)"
                  : "#c4c4c4",
              textTransform: "unset",
              fontSize: "20px",
              color: code.length == 4 ? "#fff" : "#1d1d1d",
            }}
            disabled={code.length == 4 ? false : true}
            onClick={handleOtp}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OTPModal;
