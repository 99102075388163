import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Grid } from "@mui/material";
import Telephone from "../../assets/telephone.png";
import Whatsapp from "../../assets/whatsapp.png";
import "./Navbar.css";
import TaskmoLogo from "../../assets/taskmoWhitelogo.svg";
import TaskmoLogowhite from "../../assets/fasak.svg";

const Navbar = (props) => {
  console.log(props, "propsprops");
  return (
    <>
      <AppBar
        className="appbarStyle d-flex w-100"
        position="absolute"
        style={{
          background: "#683395",
          color: "#fff",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
        }}
      >
        <Toolbar style={{ paddingBottom: "8px" }}>
          <div className="mainHeaderOne w-100">
            {/* <div className="d-flex justify-content-center">
              <img src={TaskmoLogowhite} width={"70px"} height={"70px"} />
            </div> */}
            <div className="mainHeaderTwo " style={{ alignItems: "center",paddingTop:'10px' }}>
              <div
                style={{ display: "flex", gap: "14px", alignItems: "center" }}
              >
                <div style={{ border: "2px solid #fff", borderRadius: "50%" }}>
                  <Avatar src={props.data.partner_image} />
                </div>
                <div className="mainDetails">
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: "Nunito",
                      fontWeight: "600",
                    }}
                  >
                    {props.data.partner_name}
                  </div>
                  <div style={{ fontSize: "10px", fontFamily: "Nunito" }}>
                    {" "}
                    Partner ID: <span>{props.user.user_id}</span>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <a href={`tel:${props.data.partner_number}`}>
                    <Avatar src={Telephone} sx={{ width: 24, height: 24 }} />
                  </a>
                </div>
                {/* <div>
                  <Avatar src={Whatsapp} sx={{ width: 24, height: 24 }} />
                </div> */}
              </div>
            </div>
          </div>
          {/* <div>
            <div>fasak</div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" component="div">
                <Avatar src={props.data.partner_image} />
              </Typography>
              <div>
                <Grid container direction={"column"}>
                  <Grid item>
                    <div
                      color="inherit"
                      className="navcustomername"
                      variant="subtitle1"
                      style={{ fontWeight: "600" }}
                    >
                      {props.data.partner_name}
                    </div>
                  </Grid>
                  <Grid item>
                    <div
                      color="inherit"
                      className="navcustomername"
                      variant="caption"
                      gutterBottom
                    >
                      Partner ID: <span>{props.user.user_id}</span>
                    </div>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </div>
              <div
                className="iconsonRight"
                style={{ marginLeft: "auto", textAlign: "right" }}
              >
                <div>
                  <a href={`tel:${props.data.partner_number}`}>
                    <Avatar
                      src={Telephone}
                      sx={{ width: 24, height: 24, marginRight: "16px" }}
                    />
                  </a>
                </div>
                <div>
                  <Avatar src={Whatsapp} sx={{ width: 24, height: 24 }} />
                </div>
              </div>
            </div>
          </div> */}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
