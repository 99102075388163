import React from "react";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";

import IconButton from "@mui/material/IconButton";

import axios from "axios";
import "../../../App.css";
import "../../css/LoginForm.css";

import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import "../../css/LoginForm.css";
import Chip from "@mui/material/Chip";
import OTPModal from "../../Modal/OTPModal";
import { useEffect } from "react";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { getlanguages } from "../../utils";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    // "&.Mui-focused": {
    //   // backgroundColor: "transparent",
    //   // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
    //   // borderColor: theme.palette.primary.main
    // }
  },
}));

const LoginForm = (props) => {
  const [lat, setLat] = React.useState("");
  const [long, setLong] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const [langs, setLangs] = React.useState([]);
  //latlong

  const getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
      setLoading(false);
    } else {
      console.log("Geo location is not supported by your browser");
      setLoading(false);
    }
  };

  const showPosition = (position) => {
    console.log(position, "positioncheck");
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
  };

  const getLanguages = () => {
    axios
      .get(getlanguages)
      .then((res) => {
        setLangs(res.data.lang);
        // console.log(res?.data?.lang, "checkinglang");
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    setLoading(true);
    getGeoLocation();
    getLanguages();
  }, [lat, long]);

  const Mobile_Regex = new RegExp(/^[0]?[56789]\d{9}$/);
  console.log(props, "propsone");
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [loginData, setLoginData] = React.useState({});
  const [selLang, setSelLang] = React.useState("");
  const [id, setId] = React.useState("");
  // const [numberError, setNumberError] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLogin = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "namevalue");
    setLoginData({ ...loginData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    setSelLang(loginData.language);
    langs.map((item) =>
      item.language_name == loginData.language ? setId(item.id) : ""
    );
    if (selLang == "") {
      return "";
    }

    delete loginData.language;
    console.log(loginData, "loginDatadata");
    console.log(props.data.api[0].api1, "props");
    loginData.user_id = props.user_id;
    loginData.sow_id = props.sow_id;
    axios
      .post(props.data.api[0].api1, loginData)
      .then((res) => {
        if (res.data.error) {
          localStorage.setItem("token", "accepted");
          localStorage.setItem("mernumber", loginData.merchant_number);
          navigate("/confirmation", { state: { selLang } });
        } else {
          handleClickOpen();
          localStorage.setItem("token", "rejected");
          console.log(res, "responseoutput");
        }
      })
      .catch((err) => console.log(err));
  };

  console.log(props.data.media, "askdjk");

  return isLoading ? (
    <>...loading</>
  ) : (
    <>
      <form onSubmit={handleSubmit}>
        <Grid
          className="LoginFormMain"
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid
            item
            style={
              {
                // height: "70vh",
                // marginBottom: "300px",
                // overflowX: "scroll",
              }
            }
          >
            <Card
              // raised
              sx={{
                fontFamily: "Nunito",
                boxShadow: "none",
                marginTop: "40px",
              }}
            >
              {/* <CardHeader
                style={{
                  fontFamily: "Nunito",
                }}
                // avatar={<Avatar src={props.data.brand_logo} />}
                // action={
                //   <IconButton aria-label="settings">
                //     <MoreVertIcon />
                //   </IconButton>
                // }
                // title={props.data.brand_name}
                title="Registration Process"
              /> */}
              <div
                style={{
                  paddingLeft: "20px",
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  fontWeight: "900",
                  marginTop: "28px",
                }}
              >
                Registration Process
              </div>
              <div
                className="cardMediaImagestyle"
                style={{ marginTop: "12px" }}
              >
                <video
                  width="100%"
                  poster={props.data.media}
                  controls
                  autoPlay={true}
                >
                  <source
                    src={props.data.introduction_video}
                    type="video/mp4"
                  />
                  <source src="movie.ogg" type="video/ogg" />
                </video>
                {/* <CardMedia
                  // component={props.data.type == "video" ? "iframe" : "img"}
                  component="iframe"
                  height="150"
                  // image={props.data.media}
                  src={props.data.introduction_video}
                  className="cardMediaInsideImage"
                /> */}
              </div>
              <div
                style={{
                  fontFamily: "Nunito",
                  fontSize: "11px",
                  margin: "21px",
                  marginBottom: "2px",
                  fontWeight: "600",
                }}
              ></div>

              <CardContent>
                <Grid>
                  <Chip
                    label="Please fill below details to get register"
                    style={{
                      color: "#001DF8",
                      background: "#e7e9fc",
                      fontSize: "8px",
                      paddingRight: "14px",
                      fontFamily: "Nunito",

                      height: "20px",
                      fontSize: "10px",
                      marginTop: "-17px",
                      marginBottom: "10px",
                    }}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <label
                      id="name"
                      style={{
                        color: "#292929",
                        fontFamily: "Nunito",
                        marginBottom: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Enter your name
                    </label>
                    <TextField
                      style={{
                        backgroundColor: "#f0f0f0",
                        fontFamily: "Nunito",
                        border: "none",
                        boxShadow: "none",

                        borderRadius: "4px",
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      id="name"
                      name="merchant_name"
                      fullWidth
                      onChange={handleChangeLogin}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label
                      id="name"
                      style={{
                        color: "#292929",
                        fontFamily: "Nunito",
                        marginBottom: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Enter mobile number
                    </label>
                    <TextField
                      type="number"
                      style={{
                        backgroundColor: "#f0f0f0",
                        fontFamily: "Nunito",
                        border: "none",
                        boxShadow: "none",
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      id="name"
                      variant="outlined"
                      error={
                        !Mobile_Regex.test(loginData.merchant_number)
                          ? true
                          : false
                      }
                      name="merchant_number"
                      inputProps={{
                        inputmode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      fullWidth
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onChange={handleChangeLogin}
                    />

                    {/* <RedditTextField
                      label="Number"
                      name="merchant_number"
                      id="reddit-input"
                      variant="filled"
                      InputLabelProps={{ shrink: "false" }}
                      fullWidth
                      onFocus={() => console.log("hello")}
                      size="large"
                      inputProps={{
                        maxLength: 10,
                      }}
                     
                      onChange={handleChangeLogin}
                    /> */}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label
                      id="lang"
                      style={{
                        color: "#292929",
                        fontFamily: "Nunito",
                        marginBottom: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Select Preferred Language
                    </label>
                    <TextField
                      style={{
                        backgroundColor: "#f0f0f0",
                        fontFamily: "Nunito",
                        border: "none",
                        boxShadow: "none",
                        borderRadius: "4px",
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      id="lang"
                      name="language"
                      fullWidth
                      select
                      required
                      onChange={handleChangeLogin}
                    >
                      {langs.map((item) => (
                        <MenuItem value={item.language_name}>
                          {item.language_name}
                        </MenuItem>
                      ))}
                      <MenuItem>&nbsp;</MenuItem>
                      <MenuItem>&nbsp;</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {/* <Typography
                  variant="caption"
                  display="block"
                  style={{ fontSize: "0.58rem" }}
                >
                  By clicking “ Get OTP ” I’m agreeing to{" "}
                  <span style={{ color: "#8771E0" }}>
                    {" "}
                    Taskmo Terms & Privacy policy
                  </span>
                </Typography> */}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              zIndex: "10000",
            }}
          >
            <Box
              // m={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={"100%"}
              height="100px"
              style={{
                position: "fixed",
                bottom: 0,
                background: "#fff",
                display: open ? "none" : "",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                color="primary"
                sx={{ height: 50, width: "94%" }}
                style={{
                  background:
                    Object.values(loginData).length < 3 ||
                    Object.values(loginData)[1].length != 10
                      ? "#c4c4c4"
                      : "linear-gradient(#8A7DD1, #643497)",
                  textTransform: "unset",
                  fontSize: "20px",
                  fontWeight: "600",
                  color:
                    Object.values(loginData).length < 3 ||
                    Object.values(loginData)[1].length != 10
                      ? "#1d1d1d"
                      : "#fff",
                  fontFamily: "Nunito",
                }}
                disabled={
                  Object.values(loginData).length < 3 ||
                  (Object.values(loginData)[1].length != 10 ? true : false)
                }
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      {open && (
        <OTPModal
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          lat={lat}
          long={long}
          open={open}
          handleClose={handleClose}
          mbnumber={loginData.merchant_number}
          api2={props.data.api[0].api2}
          lang={selLang}
          langId={id}
        />
      )}
    </>
  );
};

export default LoginForm;
